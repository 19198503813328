import React from "react"
import { Link } from "gatsby"
import Styles from "./css/footerlinks.module.scss"
import FooterlinksSingle from "@tightrope/footerlinks/footerlinks_single"
import siteData from "branding/site-metadata.json"


class PrivacyPolicy extends React.Component {
  constructor(props){
    super(props);
    if(props.data){
      this.state = {
        data: props.data
      }
    }
  }

  render() {
    return (
      <section>

        <div className ={Styles.footerlinksContainer}>
        <img src ={siteData.logo} className={Styles.siteLogo}/>
        <h1>Privacy Policy</h1>
        <h2>Contents</h2>
        <ol class="ppList">
          <li><a href="#data">What data may we use and how may we use it?</a></li>
          <li> <a href="#cookies">Do we use web browser cookies or
              pixels?</a> </li>
          <li> <a href="#ever-contact">Will we ever contact you?</a> </li>
          <li><a href="#share-data">Will we share this data?</a></li>
          <ol>
            <li><a href="#codefuel">4.1 Search Services by CodeFuel</a> </li>
          </ol>
          <li> <a href="#children">U.S. Children’s Online Privacy
              Protection Act (“COPPA”)</a> </li>
          <li> <a href="#cali-consumer">California Consumer Privacy Act
              (“CCPA”)</a>
            <ol>
              <li> 6.1 <a href="#6.1">Consumers With Disabilities;
                  Non-Discrimination</a> </li>
              <li> 6.2 <a href="#6.2">Designated Methods For Submitting
                  CCPA Requests</a> </li>
              <li>6.3 <a href="#6.3">Personal Information</a></li>
              <li> 6.4 <a href="#6.4">Necessity To Maintain Your Personal
                  Information</a> </li>
              <li> 6.5 <a href="#6.5">Opt-out / Opt-in For Sale Of
                  Personal Information</a> </li>
            </ol>
          </li>
          <li> <a href="#eu-data">E.U. General Data Protection Regulation
              (“GDPR”)</a>
            <ol>
              <li>7.1 <a href="#7.1">GDPR Owner</a></li>
              <li>7.2 <a href="#7.2">Personal Data</a></li>
              <li>7.3 <a href="#7.3">Disclosure</a></li>
              <li>7.4 <a href="#7.4">Data Retention</a></li>
              <li>7.5 <a href="#7.5">Contact GDPR Owner</a></li>
            </ol>
          </li>
          <li> <a href="#cws">Privacy Practices Disclosure For Chrome Web
              Store</a> </li>
          <li><a href="#policy-updates">Policy Updates</a></li>
          <li><a href="#contact-us">Contact Us</a></li>
        </ol>
        <p> This website (“site”), “service”, application (“app”), web
          browser extension (“extension”), and site and app notifications
          ("notifications") are owned and operated by Tightrope
          Interactive, Inc. (“we”, “our”, “us”), a Delaware, U.S.
          corporation. This Privacy Policy (“Privacy Policy”) pertains to
          your use (“user”, “you”, “your”) of our site, service, app,
          extension, and notifications. They may be collectively referred
          to as “products” except when specified otherwise. </p>
        <h3 id="data">1. What data may we use and how may we use it?<br />
        </h3>
        <ul>
          <li>Display Name – A name you choose to be addressed by in
            product-related communication. </li>
          <li>IP Address – Your public “Internet Protocol” address sent to
            us by your web browser. We may use your public IP address to
            approximate geography, and prevent fraudulent use of our
            products. </li>
          <li> GUID – A unique “Generic User Identification” created when
            you use our products. We may use a GUID to ensure the proper
            functionality of our products. </li>
          <li> UA – The “User Agent” and version of your web browser; e.g.
            Chrome 117, Firefox 117, Safari 17, etc. </li>
          <li> OS – The “Operating System” and version used by your
            hardware; e.g. Windows 11, macOS 14, iOS 17, etc. We may use
            your UA and OS information to optimize our products for your
            web browser and operating system. </li>
          <li> Anonymous service diagnostic data (“diagnostic data”),
            including but not limited to network connection conditions. We
            may use anonymous diagnostic data to maintain quality of
            service. </li>
          <li>Location – If you chose to opt-in, your approximate or
            precise geographic coordinates shared by your device. We may
            use your location to provide convenient functionality.<span
              style={{textDecoration: 'underline'}}></span><br />
          </li>
        </ul>
        <h3 id="cookies">2. Do we use web browser cookies or pixels?</h3>
        <ul>
          <li> Cookies are removable text files stored in your web
            browser. We may ask your web browser to store one or more
            cookies when you use our products in order to make them work
            for you. </li>
          <li> Pixels or “web beacons” are similar in function to cookies.
            We may ask your web browser to load pixels for our products to
            better understand how you use them so we can improve them, and
            for other business purposes. </li>
        </ul>
        <h3 id="ever-contact"> 3. Will we ever contact you? </h3>
        <ul>
          <li> If you contact us, we may reply using the contact
            information you provide at that time. </li>
          <li> If you chose to opt-in to notifications, we may send them
            to you via the mechanisms built-in to your User Agent or
            Operating System.</li>
        </ul>
        <h3 id="share-data">4. Will we share this data?</h3>
        <p> Your data may pass through third-party service providers that
          we use to make our products work for you. These third-parties
          may include: </p>
        <ul>
          <li>Amazon Web Services <a
              href="https://aws.amazon.com/compliance/data-privacy-faq/"
              target="_aws" moz-do-not-send="true">(Data Privacy FAQ)</a>
          </li>
          <li>Apple (<a
              href="https://www.apple.com/legal/privacy/data/en/app-store/"
              target="_app" moz-do-not-send="true">App Store &amp; Privacy</a>)<br />
          </li>
          <li>Cloudflare <a
              href="https://www.cloudflare.com/privacypolicy/"
              target="_cf" moz-do-not-send="true">(Privacy Policy)</a> </li>
          <li>CodeFuel by Perion (see Section 4.1 below)<br />
          </li>
          <li>Google <a href="https://policies.google.com/privacy"
              target="_goo" moz-do-not-send="true">(Privacy Policy)</a></li>
          <ul>
            <li>Google <a href="https://adssettings.google.com"
                target="_gad" moz-do-not-send="true">Ad Settings</a></li>
            <li>Google Analytics <a
                href="https://tools.google.com/dlpage/gaoptout"
                target="_utm" moz-do-not-send="true">Opt-out</a> </li>
          </ul>
          <li>RingCentral, Inc. (<a
              href="https://www.ringcentral.com/legal.html" target="_rin"
              moz-do-not-send="true">Privacy</a>)<br />
          </li>
          <li>Sinch Mailgun (<a
              href="https://www.mailgun.com/legal/privacy-policy/"
              target="_mai" moz-do-not-send="true">Privacy Policy</a>)<br />
          </li>
          <li>Twilio (<a href="https://www.twilio.com/legal/privacy"
              target="_twi" moz-do-not-send="true">Privacy Notice</a>)<br />
          </li>
          <li>Yahoo (<a href="https://legal.yahoo.com" target="_yah"
              moz-do-not-send="true">Terms</a>)</li>
        </ul>
        <h3 id="codefuel">4.1 Search Services by CodeFuel</h3>
        <p>Some of the web search services provided herein are provided by
          CodeFuel and powered by CodeFuel’s search partners. For
          information on the web search services data collection, please
          visit <a
            href="http://www.codefuel.com/legal/end_user_privacy_policy"
            target="_codefuel" moz-do-not-send="true">http://www.codefuel.com/legal/end_user_privacy_policy</a>,
          and the search provider's privacy policy, as applicable, both as
          updated from time to time and at any successor locations.<br />
        </p>
        <ul>
        </ul>
        <p> </p>
        <h3 id="children">5. U.S. Children’s Online Privacy Protection Act
          (“COPPA”)</h3>
        <p>We do not knowingly collect or sell information from children
          under the age of 13 pursuant to the Children’s Online Privacy
          Protection Act of 1998, 15 U.S.C. 6501-6505. We encourage
          parents and legal guardians to be familiar with the Internet
          activity of their children. For more information, visit the
          United States Federal Trade Commission <a
  href="https://consumer.ftc.gov/articles/protecting-your-childs-privacy-online"
            target="_cop" moz-do-not-send="true">website</a> about
          protecting kids online. </p>
        <h3 id="cali-consumer"> 6. California Consumer Privacy Act
          (“CCPA”) </h3>
        <p> This Section 6 applies to you if you are a natural person who
          is a California resident, as defined in Section 17014 of Title
          18 of the California Code of Regulations. This section describes
          our good faith effort to meet our obligations if CCPA applies to
          you. </p>
        <h3 id="6.1"> 6.1 Consumers With Disabilities; Non-Discrimination
        </h3>
        <p> Consumers with disabilities can submit a CCPA request as
          described below in Section 6.2. We will make a reasonable effort
          to furnish Section 6 in an alternative format that you can
          consume. </p>
        <p> You have a right not to receive discriminatory treatment by us
          for the exercise of the privacy rights conferred by the CCPA. </p>
        <h3 id="6.2"> 6.2 Designated Methods For Submitting CCPA Requests
        </h3>
        <p> You can contact us to submit a CCPA request via <a
  href="https://privacy.tightropeinteractive.com/?domain=Wallpapermania.org">this webform</a>, or by calling our automated, toll-free (within
          the United States) phone number: 1-833-874-2272. Please be aware
          that your public Caller ID information may be collected for
          business purposes if you choose the phone call method. </p>
        <p> <em>For inquiries about our Privacy Policy that are not
            related to CCPA, please use the contact information at the end
            of this Privacy Policy.</em> </p>
        <h3 id="6.3">6.3 Personal Information</h3>
        <p> The State of California Civil Code, Division 3, Part 4, Title
          1.81.5. California Consumer Privacy Act of 2018 [1798.100 –
          1798.199], CCPA, defines “personal information” as including
          “…information that identifies, relates to, describes, is capable
          of being associated with, or could reasonably be linked,
          directly or indirectly, with a particular consumer or
          household." </p>
        <h3 id="6.4"> 6.4 Necessity To Maintain Your Personal Information
        </h3>
        <p> The <a moz-do-not-send="true" href="#data">personal
            information</a> we may collect may be used for the following
          business purposes, which are necessary for us in order to: </p>
        <ul>
          <li>Offer you our products.</li>
          <li> Detect security incidents, protect against malicious,
            deceptive, fraudulent, or illegal activity. </li>
          <li> Debug to identify and repair errors that impair existing
            intended functionality. </li>
        </ul>
        <h3 id="6.5"> 6.5 Opt-out / Opt-in For Sale Of Personal
          Information </h3>
        <p> We do not explicitly sell your personal information;
          therefore, there is no need to opt-out of the sale of your
          personal information. You may submit a CCPA request as described
          in Section 6.2 to preemptively opt-out or opt-in your personal
          information to a sale if one is made within 12-months from the
          date we receive your request. </p>
        <h3 id="eu-data"> 7. E.U. General Data Protection Regulation
          (“GDPR”) </h3>
        <p> This Section 7 applies to you if you are a national of a
          European Economic Area (“EEA”) member state. This section
          describes our good faith effort to meet our obligations if GDPR
          applies to you. </p>
        <h3 id="7.1">7.1 GDPR Owner</h3>
        <p> You can contact our GDPR owner via <a
  href="mailto:GDPRrequest+WallpaperManiaOrg@TightropeInteractive.com?subject=Privacy%20Policy%20-%20GDPR%20Request&amp;body=(Please%20do%20not%20modify%20the%20email%20subject%20line.)%0A%0AInclude%20the%20domain%20name%20or%20web%20address%20of%20the%20site%20(e.g.%20Wallpapermania.org)%20you%20are%20inquiring%20about%20here:">this email link</a>, or post: </p>
        <p> Tightrope Interactive, Inc.<br />
          ATTN: GDPR Request<br />
          248 3rd St. #312<br />
          Oakland, CA 94607-4375<br />
          USA</p>
        <p> <em>For inquiries about our Privacy Policy that are not
            related to GDPR, please use the contact information at the end
            of this Privacy Policy.</em> </p>
        <h3 id="7.2">7.2 Personal Data</h3>
        <p> European Union regulation 2016/679, GDPR, defines “personal
          data” as “…any information relating to an identified or
          identifiable natural person (‘data subject’); an identifiable
          natural person is one who can be identified, directly or
          indirectly, in particular by reference to an identifier such as
          a name, an identification number, location data, an online
          identifier or to one or more factors specific to the physical,
          physiological, genetic, mental, economic, cultural or social
          identity of that natural person” </p>
        <p> The <a moz-do-not-send="true" href="#data">personal data </a>
          we may collect may be used for the following purposes: </p>
        <ul>
          <li>Fraud detection to prevent abuse of our products.</li>
          <li> Geography detection to aid with fraud detection, and the
            delivery of our products appropriate for your location. </li>
        </ul>
        <p>Our basis for processing personal data includes:</p>
        <ul>
          <li> GDPR Recital 47: “The processing of personal data strictly
            necessary for the purposes of preventing fraud also
            constitutes a legitimate interest of the data controller
            concerned.” </li>
          <li> GDPR Article 6(1)(f): “processing is necessary for the
            purposes of the legitimate interests pursued by the controller
            or by a third party…” </li>
        </ul>
        <h3 id="7.3">7.3 Disclosure</h3>
        <p> We may pass your personal data without first obtaining your
          consent to third parties listed in Section 4<span
            style={{textDecoration: 'underline'}}></span>. </p>
        <h3 id="7.4">7.4 Data Retention</h3>
        <p> We may retain your public IP address in server logs for a time
          period of at least 30 days. GUID data may be retained for a
          period of at least 90 days after the last use of our products by
          a user with a GUID. </p>
        <h3 id="7.5">7.5 Contact GDPR Owner</h3>
        <p> Please contact our GDPR owner as described in Section 7.1 to
          exercise your rights as a GDPR data subject, or to file a
          complaint. The GDPR owner can help you with GDPR-specific
          matters including: </p>
        <ul>
          <li> Request a copy of information, if any, stored about you. </li>
          <li>Correct information, if any, stored about you.</li>
          <li>Erase information, if any, stored about you.</li>
          <li>Restrict how information, if any, about you is used.</li>
          <li>Transfer the information, if any, about you.</li>
          <li> Object to how your information, if any, is used in certain
            cases. </li>
          <li> Request why we are unable to fulfill your request, if
            applicable. </li>
        </ul>
        <h3 id="cws"> 8. Privacy Practices Disclosure For Chrome Web Store
        </h3>
        <p> Pursuant to Section 6 of the Google Chrome Web Store “Updated
          Privacy Policy &amp; Secure Handling Requirements”, our
          extensions for Google Chrome collect your “Location” (e.g.
          region, IP address), and the “User Activity” (e.g. clicks) of
          our extensions. We use this information to understand the usage
          of and improve our products. </p>
        <p> Our Chrome extensions request the minimum browser permissions
          and site permissions required to function. You as the user can
          review the “Site access” and “Permissions” of extensions by
          opening chrome://extensions in Chrome. </p>
        <p> We do not sell this data to third parties, use or transfer
          this data for purposes that are unrelated to the item’s
          (extension’s) core functionality, or use or transfer this data
          to determine creditworthiness or for lending purposes knowingly
          by us. </p>
        <h3 id="policy-updates">9. Policy Updates</h3>
        <p> We reserve the right to modify this Privacy Policy at any
          time.</p>
        <h3 id="contact-us">10. Contact Us</h3>
        <p> If you have questions or concerns about this Privacy Policy,
          its implementation, or feel that we have not followed it, please
          let us know by email at <a moz-do-not-send="true"
            href="mailto:contactus@Wallpapermania.org">contactus@Wallpapermania.org </a>
          or post: </p>
        <p> Tightrope Interactive, Inc.<br />
          ATTN: Privacy Policy – General Inquiry<br />
          248 3rd St. #312<br />
          Oakland, CA 94607-4375<br />
          USA<br />
        </p>

    <FooterlinksSingle></FooterlinksSingle>
        </div>
        <script
        dangerouslySetInnerHTML={{
          __html: `
       let domain = window.location.hostname ;
		const splitArr = domain.split(".");
		const arrLen = splitArr.length;
if (arrLen > 2) {
  domain = splitArr[arrLen - 2] + "." + splitArr[arrLen - 1];
  if (
    (splitArr[arrLen - 2].length === 2 &&
    splitArr[arrLen - 1].length === 2)
  ) {
    domain = splitArr[arrLen - 3] + "." + domain;
  }
}






		<!--//notifications
(function(){
        window.notificationCommands = window.notificationCommands || [];
        function adn(){
            window.notificationCommands.push(arguments);
        }
        adn("config",{
              serviceDomain:"https://notifications."+domain,
              workerUrl:"/service_worker.js",
             "askFrequency": 1   ,
              tags:{
                  extInstalled:false,
                  "partner":domain
              }
        })
        adn("tagsFromQuery",{"partner":"p","campaign":"c"},"user_type");
    })();


		  var scr = document.createElement( 'script' );
  scr.setAttribute( 'src', "https://notifications."+ domain +"/js/latest/client.js" );
scr.defer = true;
  document.body.appendChild( scr );



		var ntfn_src = document.createElement( 'script' );
   ntfn_src.setAttribute( 'src', "https://notifications."+ domain +"/js/latest/unsubscribe.js" );
   ntfn_src.defer = true;
  document.body.appendChild( ntfn_src );`}}
  />
      </section>
    )
  }
}
export default PrivacyPolicy
